<template>
  <div class="wrapper">
    <!--问题类型-->
    <template v-for="(item, index) in problemList">
      <div class="problem-sort-item" :key="index">
        <div class="list-item problem-sort-item-name">{{ item.dic_name }}</div>
        <!--具体问题-->
        <template
          v-for="(im, ix) in item.problemList"
        >
          <div class="list-item-box" :key="ix">
            <div
              class="list-item flex jc-sb ai-c"
              @click="im.show=!im.show"
            >
              <div>{{ im.problem }}</div>
              <div class="down-arrow" :class="im.show?'top-arrow':''"></div>
            </div>
            <div class="answer-box flex" v-show="im.show">
              <div class="answer-icom">答</div>
              <div class="answer-content">{{ im.answer }}</div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
        problemList: [{
            dic_name: '关于产品',
            problemList: [{
                problem: '是进口？',
                answer: '是的，全球知名品牌，原产地直供，正规渠道进货，让你放心使用正品。',
                show: false
            }]
        },{
            dic_name: '关于产品',
            problemList: [{
                problem: '是进口？',
                answer: '是的，全球知名品牌，原产地直供，正规渠道进货，让你放心使用正品。',
                show: false
            }]
        }]
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: RGBA(238, 238, 238, 1);
  overflow: hidden;
}
.problem-sort-item {
  margin-top: .25rem;
  background: #fff;
  overflow: hidden;
}
.list-item {
  padding: 0 .40rem;
  line-height: 1.00rem;
  font-size: .28rem;
  color: #838383;
  border-bottom: 1px solid #f8f8f8;
}
.problem-sort-item-name.list-item {
  padding: 0 .30rem;
  font-size: .32rem;
  color: #000;
  justify-content: flex-start;
  font-weight: bold;
}
.list-item:last-child {
  border-bottom: 0;
}
.list-item-box {
  width: 100%;
  position: relative;
}
.down-arrow {
  width: .18rem;
  height: .18rem;
  border-right: .03rem solid #4c4c4c;
  border-bottom: .03rem solid #4c4c4c;
  transform: rotate(45deg);
  position: relative;
  transition: all 0.5s;
}
.top-arrow {
  transform: rotate(-135deg);
  position: relative;
  top: .10rem;
}

.answer-box {
  position: relative;
  background: #f7f7f7;
  padding: .20rem .60rem .20rem .15rem;
  align-items: flex-start;
  color: #848484;
  font-size: .28rem;
  left: .30rem;
  margin-bottom: .20rem;
}
.answer-box:before {
  content: "";
  width: 0;
  height: 0;
  border-right: .16rem solid transparent;
  border-left: .16rem solid transparent;
  border-bottom: .22rem solid #f7f7f7;
  position: absolute;
  top: -.20rem;
  left: .30rem;
}
.answer-icom {
  font-size: .30rem;
  color: #fff;
  width: .40rem;
  line-height: .40rem;
  text-align: center;
  background: rgba(243, 94, 58, 1);
}
.answer-content {
  width: 90%;
  margin-left: .1rem;
}
</style>